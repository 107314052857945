.preview {
  width: 100%;
  background-color: #e6e6e6;
  display: flex;
  align-items: center;
  justify-content: center;
}

.prebiew__inner {
  width: 600px;
  background-color: #fff;
  display: grid;
  gap: 30px;
  grid-template-columns: 345px auto;
  grid-template-rows: auto auto 1fr;
  height: 862.5px;
  width: 600px;
  padding: 15px;
}
